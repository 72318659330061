import * as React from 'react';
import { forwardRef, useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useRefresh, useNotify, useMutation } from 'react-admin';
import clsx from 'clsx';

import {
	Typography,
	Button,
	Dialog,
	Slide,
	AppBar,
	Toolbar,
	TextField,
	DialogActions,
	IconButton,
	makeStyles,
	createStyles,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
	DialogContent
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { EditListProfileProps } from './DialogsProps';
import CloseIcon from '@material-ui/icons/Close';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import AddIcon from '@material-ui/icons/Add';
import { CurrencyTypesTable } from '../CurrencyTypesTable';
import NumberFormat from 'react-number-format';
import DatePicker from 'react-datepicker';
import CustomInput from '../CustomInputEdit';
import { format } from 'date-fns';
import 'react-dropdown-tree-select/dist/styles.css';

const Transition = forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement },
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});
interface Expiration {
	validityPeriod: any;
	expirationDate: any;
}

interface NumberFormatCustomProps {
	inputRef: (instance: NumberFormat<any> | null) => void;
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
}

function IntegerFormatter(props: NumberFormatCustomProps) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value
					}
				});
			}}
			thousandSeparator
			allowNegative={false}
			decimalScale={0}
		/>
	);
}

export const EditValueCodeListProfileDialog = (
	{
		openEditListProfileDialog,
		editListProfileDialogClosed,
		editListProfileClicked,
		list
	}: EditListProfileProps,
	props: any
) => {
	const [locationsToSelect, setLocationsToSelect] = useState<any>([]);
	const [listName, setListName] = useState('');
	const [location, setLocation] = useState('');
	const [service, setService] = useState('');
	const [services, setServices] = useState<any>([]);
	const [locations, setLocations] = useState<any>([]);
	const [initialAmounts, setInitialAmounts] = useState<any[]>([]);
	const [initialAmountEdit, setInitialAmountEdit] = useState<any[]>([]);
	const [expirationSelect, setExpirationSelect] = useState('noExpiration');
	const [openCalendar, setOpenCalendar] = useState(false);
	const [expiration, setExpiration] = useState<Expiration | null>({
		expirationDate: null,
		validityPeriod: null
	});
	const classes = useStyles();
	const [mutate] = useMutation();
	const refresh = useRefresh();
	const notify = useNotify();

	useEffect(() => {
		setServices(JSON.parse(localStorage.getItem('cscvc_filterServices') as string));
		setLocations(JSON.parse(localStorage.getItem('cscvc_filterLocations') as string));
	}, []);

	useEffect(() => {
		setDefaultLocationsSelect();
	}, [locations, location]);

	useEffect(() => {
		if (list.settingsJSON) setInitialAmounts(list.settingsJSON.initialAmounts);
		if (list.settingsJSON !== null)
			setInitialAmountEdit(
				list.settingsJSON.initialAmounts.map(() => ({ edit: false, monetary: false }))
			);
	}, [list]);

	useEffect(() => {
		setDefaultLocationsSelect();
		setListName(list.name);
		setService(list.serviceId);
		setLocation(list.locationId);
		setExpirationSelect(
			list.settingsJSON?.expiration?.validityPeriod !== null
				? 'period'
				: list.settingsJSON?.expiration?.expirationDate !== null
				? 'expirationDate'
				: 'noExpiration'
		);
		setExpiration({
			expirationDate: list.settingsJSON?.expiration?.expirationDate,
			validityPeriod: list.settingsJSON?.expiration?.validityPeriod
		});
	}, [openEditListProfileDialog]);

	const setDefaultLocationsSelect = () => {
		let locationsArray: any[] = [];
		locationsArray = locations
			.filter((location: any) => location.parentId == null)
			.map((loc: any) => {
				return {
					label: loc.name,
					value: loc.id,
					checked: location === loc.id,
					children:
						loc.childrenLocations === 0
							? []
							: locations
									.filter((childrenLocation: any) => childrenLocation.parentId === loc.id)
									.map((childLoc: any) => {
										return {
											label: childLoc.name,
											value: childLoc.id,
											checked: location === childLoc.id
										};
									})
				};
			});
		setLocationsToSelect(locationsArray);
	};

	const onEditServiceClicked = () => {
		let filteredInitialAmounts: any[] = [];
		filteredInitialAmounts = initialAmounts.filter(
			(v, i, a) => a.findIndex((t) => t.currencyId === v.currencyId) === i
		);
		if (filteredInitialAmounts.length !== initialAmounts.length) {
			notify(`You have duplicated currency types.`, 'warning');
		} else {
			mutate(
				{
					type: 'update',
					resource: 'valuecodelists',
					payload: {
						id: list.id,
						data: {
							name: listName,
							locationId: location,
							serviceId: service,
							settingsJSON: {
								initialAmounts,
								expiration: {
									validityPeriod: expiration?.validityPeriod,
									expirationDate:
										expiration?.expirationDate !== null ? expiration?.expirationDate : null
								}
							}
						}
					}
				},
				{
					onSuccess: () => {
						onCancelClicked();
						refresh();
						notify(`Value Code List updated: ${list.id}`);
					},
					onFailure: (error) => {
						notify(`Value Code List not updated`, 'warning');
						console.log('Error: ' + error);
					}
				}
			);
		}
	};

	const onCancelClicked = () => {
		editListProfileDialogClosed();
	};

	const listNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
		setListName(event.target.value);
	};

	const handleSelectChange = (event: any) => {
		if (event.target.name === 'service') {
			setService(event.target.value);
		}
		if (event.target.name === 'expiration') {
			setExpirationSelect(event.target.value);
			setExpiration({
				expirationDate: list.settingsJSON?.expiration?.expirationDate,
				validityPeriod: list.settingsJSON?.expiration?.validityPeriod
			});
		}
	};

	function renderServices() {
		const servicesArray: any[] = [];
		services.forEach((service: any) => {
			if (service.active)
				servicesArray.push(<MenuItem value={service.id}>{service.name}</MenuItem>);
		});

		return servicesArray;
	}

	const locationChanged = (currentNode: any) => {
		const location = currentNode.value;
		const locationsArray = locationsToSelect.slice();
		locationsArray.forEach((loc: any) => {
			if (loc.value === location) {
				loc.checked = currentNode.checked;
			} else loc.checked = false;
			loc.children.forEach((childLoc: any) => {
				if (childLoc.value === location) {
					childLoc.checked = currentNode.checked;
				} else childLoc.checked = false;
			});
		});
		setLocationsToSelect(locationsArray);
		setLocation(currentNode.value);
	};

	const saveButtonDisabled = () => {
		let pendingEdit = false;
		initialAmountEdit.forEach((element: any) => {
			if (element.edit === true) pendingEdit = true;
		});
		if (isEmpty(listName.trim()) || isEmpty(service.trim()) || pendingEdit) return true;
		else return false;
	};

	const addCurrencyType = () => {
		const initialAmountsCopy = initialAmounts.slice();
		const initialAmountsEditCopy = initialAmountEdit.slice();
		initialAmountsCopy.push({
			currency: '',
			currencyId: '',
			amount: ''
		});
		initialAmountsEditCopy.push({ edit: true });
		setInitialAmounts(initialAmountsCopy);
		setInitialAmountEdit(initialAmountsEditCopy);
	};

	const validityPeriodChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
		setExpiration({
			expirationDate: null,
			validityPeriod: event.target.value
		});
	};

	const onChangeExpirationDate = (date: any) => {
		const formatDate = format(date, 'MM/dd/yyyy');
		setExpiration({
			expirationDate: formatDate,
			validityPeriod: null
		});
	};

	return (
		<Dialog
			open={openEditListProfileDialog}
			onClose={editListProfileDialogClosed}
			TransitionComponent={Transition}
		>
			<AppBar className={classes.addServiceDialogAppBar}>
				<Toolbar>
					<Typography className={classes.addServiceDialogTitle}>Edit Value Code List</Typography>
					<IconButton aria-label='close' className={classes.closeBtn} onClick={onCancelClicked}>
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<DialogContent className={classes.dialogContent}>
				<div className={classes.addRoomDialogItem}>
					<span className={classes.menuTitle}>List Info</span>
					<div className={classes.field}>
						<TextField
							error={listName === ''}
							id='outlined-helperText'
							variant='outlined'
							label='List Name'
							size='small'
							onChange={listNameChanged}
							value={listName}
						/>
					</div>
					{listName === '' && (
						<span className={classes.errorText}>Please enter the List Name.</span>
					)}
					<div className={classes.field}>
						<FormControl variant='outlined'>
							<InputLabel
								htmlFor='service'
								className={clsx(service === '' ? classes.errorTextSelect : '')}
							>
								Service
							</InputLabel>
							<Select
								error={service === ''}
								value={service}
								onChange={handleSelectChange}
								label='Service'
								inputProps={{
									name: 'service',
									id: 'outlined-age-native-simple'
								}}
							>
								<MenuItem value=''>
									<em>Select a service...</em>
								</MenuItem>
								{renderServices()}
							</Select>
						</FormControl>
					</div>
					{service === '' && <span className={classes.errorText}>Please select the Service.</span>}
					<div className={classes.field}>
						<DropdownTreeSelect
							data={locationsToSelect}
							mode='radioSelect'
							className={classes.locationsSelect}
							onChange={locationChanged}
							inlineSearchInput={true}
							keepTreeOnSearch={true}
							texts={{ placeholder: 'Location' }}
						/>
					</div>
					<div className={classes.field}>
						<FormControl variant='outlined'>
							<InputLabel htmlFor='service'>Expiration</InputLabel>
							<Select
								value={expirationSelect}
								onChange={handleSelectChange}
								label='Expiration'
								inputProps={{
									name: 'expiration',
									id: 'outlined-age-native-simple'
								}}
							>
								<MenuItem value='noExpiration'>No expiration</MenuItem>
								<MenuItem value='period'>Valid for set period</MenuItem>
								<MenuItem value='expirationDate'>Expires on specific date</MenuItem>
							</Select>
						</FormControl>
					</div>

					{expirationSelect === 'period' && (
						<div className={classes.field}>
							<TextField
								id='outlined-helperText'
								variant='outlined'
								label='Validity Period'
								size='small'
								onChange={validityPeriodChanged}
								value={expiration?.validityPeriod}
								InputProps={{
									inputComponent: IntegerFormatter as any
								}}
							/>
						</div>
					)}
					{expirationSelect === 'expirationDate' && (
						<div className={classes.field}>
							<DatePicker
								className={classes.wrapper}
								calendarClassName={classes.calendar}
								dateFormat={'MM/dd/yyyy'}
								minDate={new Date()}
								value={
									expiration?.expirationDate !== null
										? format(new Date(expiration?.expirationDate), 'MM/dd/yyyy')
										: format(new Date(), 'MM/dd/yyyy')
								}
								selected={
									expiration?.expirationDate !== null ? new Date(expiration?.expirationDate) : null
								}
								onChange={onChangeExpirationDate}
								onInputClick={() => setOpenCalendar(true)}
								onClickOutside={() => setOpenCalendar(false)}
								open={openCalendar}
								customInput={<CustomInput label={'Expiration Date'} labelWidth={120} />}
							/>
						</div>
					)}

					<span className={classes.menuTitle}>Currency Types</span>
					<CurrencyTypesTable
						list={list}
						initialAmounts={initialAmounts}
						setInitialAmounts={setInitialAmounts}
						initialAmountEdit={initialAmountEdit}
						setInitialAmountEdit={setInitialAmountEdit}
					/>
					<Button
						onClick={addCurrencyType}
						size='small'
						variant='contained'
						startIcon={<AddIcon />}
						color='default'
						classes={{
							root: classes.addCuurencyTypeBtnRoot,
							label: classes.cancelBtnTxt
						}}
					>
						Add currency type
					</Button>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={onCancelClicked}
					size='small'
					variant='contained'
					color='default'
					classes={{
						root: classes.cancelBtnRoot,
						label: classes.cancelBtnTxt
					}}
				>
					Cancel
				</Button>
				<Button
					disabled={saveButtonDisabled()}
					onClick={onEditServiceClicked}
					size='small'
					color='secondary'
					variant='contained'
					classes={{
						root: classes.yesBtnRoot,
						label: classes.yesBtnTxt,
						disabled: classes.addRoomBtnDisabled
					}}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const useStyles = makeStyles(() =>
	createStyles({
		addServiceDialogAppBar: {
			position: 'relative',
			backgroundColor: '#EEEFF3',
			boxShadow: '0 0 0',
			borderBottom: '1px solid #E2E3E9',
			paddingRight: '0 !important'
		},
		addServiceDialogTitle: {
			flex: 1,
			paddingLeft: '8px',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 800,
			fontSize: '24px',
			lineHeight: '33px',
			letterSpacing: '-0.75px',
			color: '#232F64'
		},
		addRoomBtnDisabled: {
			opacity: 0.3
		},
		addRoomDialogItem: {
			margin: '10px',
			width: '450px',
			display: 'flex',
			flexDirection: 'column'
		},
		cancelBtnRoot: {
			background: '#FFFFFF',
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			borderRadius: '8px',
			width: '152px',
			height: '36px',
			marginBottom: '25px'
		},
		cancelBtnTxt: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '16px',
			lineHeight: '22px',
			letterSpacing: '0.5px',
			color: '#5F5F5F'
		},
		yesBtnRoot: {
			marginLeft: '16px',
			background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)',
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			borderRadius: '8px',
			width: '152px',
			height: '36px',
			marginRight: '25px',
			marginBottom: '25px'
		},
		yesBtnTxt: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '16px',
			lineHeight: '22px',
			letterSpacing: '0.5px',
			color: '#FFFFFF'
		},
		closeBtnRoot: {
			background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)',
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			borderRadius: '8px',
			width: '214px',
			height: '36px'
		},
		closeBtn: {
			color: '#969696'
		},
		addCurrencyTypeDialogItem: {
			margin: '25px 35px 5px 35px',
			width: '300px',
			display: 'flex',
			alignItems: 'center'
		},
		field: {
			margin: '12px 0 5px 0',
			width: '460px',
			display: 'flex',
			alignItems: 'center'
		},
		locationsSelect: {
			fontFamily: 'Manrope',
			'& a': {
				borderRadius: '8px',
				padding: '7px 12px 7px 12px !important',
				maxHeight: '36px !important',
				width: '438px',
				'& li': {
					width: '410px',
					'& span': {
						fontSize: '18px',
						fontFamily: 'Manrope',
						fontWeight: 300,
						fontStyle: 'normal',
						color: '#a99a9a'
					}
				}
			},
			'& .dropdown .dropdown-trigger.arrow.bottom:after': {
				fontSize: '17px',
				color: 'rgba(0, 0, 0, 0.54)',
				content: '"\\23F7"'
			},
			'& .dropdown .dropdown-trigger.arrow.top:after': {
				fontSize: '17px',
				color: 'rgba(0, 0, 0, 0.54)',
				content: '"\\23F6"'
			},
			'& .dropdown .dropdown-content li': {
				fontSize: '18px'
			},
			'& .dropdown .dropdown-content': {
				zIndex: 2,
				'& input': {
					fontSize: '16px',
					fontFamily: 'Manrope',
					fontWeight: 500,
					fontStyle: 'normal',
					lineHeight: '22px'
				}
			},
			'& .dropdown': {
				maxWidth: '360px'
			}
		},
		menuTitle: {
			width: '100%',
			fontWeight: 700,
			fontSize: '18px',
			fontFamily: 'Manrope',
			borderBottom: '1px solid #E2E3E9',
			paddingBottom: '5px',
			margin: '10px 0'
		},
		addCuurencyTypeBtnRoot: {
			background: '#FFFFFF',
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			borderRadius: '8px',
			width: '233px',
			height: '36px',
			marginTop: '5px'
		},
		noResults: {
			fontWeight: 500,
			fontSize: '16px',
			fontFamily: 'Manrope',
			margin: '5px'
		},
		errorText: {
			fontWeight: 700,
			fontSize: '12px',
			fontFamily: 'Manrope',
			color: '#EB5757',
			marginLeft: '10px'
		},
		errorTextSelect: {
			color: '#EB5757 !important'
		},
		wrapper: {
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'space-around',
			overflow: 'hidden',
			backgroundColor: 'white'
		},
		calendar: {
			backgroundColor: '#ffffff',
			border: 'none'
		},
		dialogContent: {
			overflowX: 'hidden'
		}
	})
);
