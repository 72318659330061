import React, { useEffect, useState } from 'react';

import {
	makeStyles,
	createStyles,
	Theme,
	Typography,
	Button,
	Backdrop,
	CircularProgress,
	Divider,
	Menu,
	MenuItem
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useMutation, useNotify, useRefresh, useDataProvider } from 'react-admin';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { ChangeListStatus } from './Dialogs/ChangeValueCodeListDialog';
import { EditValueCodeListProfileDialog } from './Dialogs/EditValueCodeListProfileDialog';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import clsx from 'clsx';
import { hasEdit, hasDelete } from '../../utils/permissions';

export const ValueCodeListsHeaderGrids = (props: any) => {
	const classes = useStyles();
	const history = useHistory();
	const notify = useNotify();
	const refresh = useRefresh();
	const dataProvider = useDataProvider();
	const [active, setActive] = useState(false);
	const [activeCodes, setActiveCodes] = useState(null);
	const [openDisableVCLDialog, setOpenDisableVCLDialog] = useState(false);
	const [mutate] = useMutation();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const [openEditListProfileDialog, setOpenEditListProfileDialog] = useState(false);

	useEffect(() => {
		dataProvider
			.getOne('valuecodelists/activeCodes', { id: props.record.id })
			.then(({ data }: any) => {
				setActiveCodes(data.activeCodes);
			})
			.catch((error: any) => {
				console.log(error);
			});
	}, [dataProvider, props.record.id]);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const firstBreadcrumbTitleClicked = () => {
		history.push(`${props.basePath}`);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const onClickDisable = () => {
		setOpenDisableVCLDialog(true);
		setActive(false);
	};

	const onClickEnable = () => {
		setOpenDisableVCLDialog(true);
		setActive(true);
	};

	const disableVCLDialogDeleteClicked = () => {
		mutate(
			{
				type: 'delete',
				resource: 'valuecodelists',
				payload: {
					id: props.record.id
				}
			},
			{
				onSuccess: () => {
					refresh();
					notify(`Value Code List:${props.record.id} Edited`);
				},
				onFailure: (error) => {
					notify(`Value Code List:${props.record.id} not Edited`, 'warning');
					console.log('Error: ' + error);
				}
			}
		);
		setOpenDisableVCLDialog(false);
	};

	const disableVCLDialogClosed = () => {
		setOpenDisableVCLDialog(false);
	};

	const renderCurrencies = () => {
		const currencies: any[] = [];
		if (props.record.settingsJSON !== null && props.record.settingsJSON.initialAmounts.length > 0) {
			props.record.settingsJSON.initialAmounts.forEach((element: any, index: number) => {
				currencies.push(
					index > 0 ? (
						<span className={classes.labelText}>
							, {props.record.settingsJSON.initialAmounts[index].currency}
						</span>
					) : (
						<span className={classes.labelText}>
							{props.record.settingsJSON.initialAmounts[index].currency}
						</span>
					)
				);
			});
			return currencies;
		} else return '-';
	};

	const editList = () => {
		setOpenEditListProfileDialog(true);
	};

	const onClickCloseEditListProfileDialog = () => {
		setOpenEditListProfileDialog(false);
	};

	const renderActivateDeactivateMenu = () => {
		return (
			<Menu
				id='simple-menu'
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem onClick={onClickEnable}>Activate</MenuItem>
				<MenuItem onClick={onClickDisable}>Deactivate</MenuItem>
			</Menu>
		);
	};

	return (
		<div className={classes.showTitle}>
			<div className={classes.titleContainer}>
				<div className={classes.breadcrumsContainer}>
					<Typography noWrap className={classes.breadcrumbTitle}>
						<span className={classes.firstBreadcrumbTitle} onClick={firstBreadcrumbTitleClicked}>
							Value Code Lists
						</span>

						<NavigateNextIcon fontSize='small' className={classes.navigationIcon} />

						<span className={classes.secondBreadcrumbTitle}>
							{props.record?.name || 'List Name'}
						</span>
					</Typography>
					<div className={classes.creatorInfo}>
						<span className={classes.label}>{props.record?.id}</span>
						<span className={classes.divider3} />
						<span className={classes.labelText}>
							Created &nbsp;{moment(props.record?.createdOn).format('MM/DD/YYYY')} by{' '}
							{props.record?.createdBy}
						</span>
					</div>
				</div>
				<div className={classes.secondTitleContainer}>
					<Typography noWrap className={classes.secondTitle}>
						{`${props.record?.name || 'List Name'}`}
					</Typography>
					<div className={classes.optionsContainer}>
						{hasDelete(null, 'valueCodeLists') ? (
							<div className={classes.activeDeactiveBtn}>
								<Button
									size='small'
									variant='contained'
									color='default'
									onClick={handleClick}
									startIcon={
										<CircleIcon
											className={clsx(
												props.record.active ? classes.activeIndicator : classes.inactiveIndicator
											)}
										/>
									}
									endIcon={<ArrowDropDownIcon className={classes.arrowDropDown} />}
									classes={{
										root: classes.statusBtnRoot,
										label: classes.statusBtnTxt
									}}
								>
									{props.record.active === true && (
										<>
											<span>Active</span>
										</>
									)}
									{props.record.active !== true && (
										<>
											<span>Inactive</span>
										</>
									)}
								</Button>
							</div>
						) : (
							<div></div>
						)}
						{hasEdit(null, 'valueCodeLists') ? (
							<Button
								variant='contained'
								size='small'
								onClick={editList}
								classes={{
									root: classes.editBtnRoot,
									label: classes.editBtnTxt
								}}
							>
								Edit List
							</Button>
						) : (
							<div></div>
						)}
					</div>
				</div>
				<div className={classes.thirdTitleContainer}>
					<span className={classes.label}>Service:&nbsp;</span>
					<span className={classes.labelText}>{props.record?.service?.name || '-'}</span>
					<Divider orientation='vertical' className={classes.divider2} />
					<span className={classes.label}>Location:&nbsp;</span>
					<span className={classes.labelText}>{props.record?.location?.name || '-'}</span>
					<Divider orientation='vertical' className={classes.divider2} />
					{props.record.settingsJSON !== null &&
						props.record.settingsJSON.initialAmounts.length > 1 && (
							<span className={classes.label}>Currencies:&nbsp;</span>
						)}
					{(props.record.settingsJSON?.initialAmounts.length === 1 ||
						props.record.settingsJSON?.initialAmounts.length === 0 ||
						props.record.settingsJSON?.initialAmounts === null ||
						props.record.settingsJSON === null) && (
						<span className={classes.label}>Currency:&nbsp;</span>
					)}
					{renderCurrencies()}
					<Divider orientation='vertical' className={classes.divider2} />
					<span className={classes.label}>Active Codes:&nbsp;</span>
					<span className={classes.labelText}>
						{activeCodes} of {props.record.totalCodes}
					</span>
				</div>
			</div>
			<Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
				<CircularProgress color='inherit' />
			</Backdrop>
			{hasDelete(null, 'valueCodeLists') ? (
				<ChangeListStatus
					deleteRoomClosed={disableVCLDialogClosed}
					openDeleteRoom={openDisableVCLDialog}
					deleteRoomDeleteClicked={disableVCLDialogDeleteClicked}
					active={active}
				/>
			) : (
				<></>
			)}
			{hasEdit(null, 'valueCodeLists') ? (
				<EditValueCodeListProfileDialog
					openEditListProfileDialog={openEditListProfileDialog}
					editListProfileDialogClosed={onClickCloseEditListProfileDialog}
					editListProfileClicked={onClickCloseEditListProfileDialog}
					list={props.record}
				/>
			) : (
				<></>
			)}
			{renderActivateDeactivateMenu()}
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		showTitle: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			minWidth: '1376px'
		},
		titleContainer: {
			background: 'white',
			borderBottom: '1px solid #E2E3E9',
			height: '182px'
		},
		breadcrumsContainer: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			marginTop: '5px',
			marginLeft: '40px',
			marginRight: '40px',
			marginBottom: '13px'
		},
		creatorInfo: {
			marginTop: '5px'
		},
		breadcrumbTitle: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '16px',
			lineHeight: '22px',
			letterSpacing: '-0.5px',
			color: '#969696'
		},
		firstBreadcrumbTitle: {
			'&:hover': {
				cursor: 'pointer'
			}
		},
		navigationIcon: {
			position: 'relative',
			top: '5px',
			margin: '0px 5px 0px 6px',
			color: '#BDBDBD'
		},
		arrowDropDownIcon: {
			position: 'relative',
			color: '#5F5F5F'
		},
		secondBreadcrumbTitle: {
			color: '#5F5F5F'
		},
		secondTitleContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			borderTop: '1px solid #E0E0E0'
		},
		thirdTitleContainer: {
			display: 'flex',
			justifyContent: 'left',
			marginTop: '21px',
			marginLeft: '40px'
		},
		secondTitle: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontSize: '44px',
			fontWeight: 800,
			lineHeight: '113%',
			marginLeft: '40px',
			marginTop: '21px',
			color: '#232F64',
			letterSpacing: '-1px'
		},
		activeDeactiveBtn: {
			marginTop: '21px',
			marginRight: '10px',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 500,
			fontSize: '18px',
			lineHeight: '25px',
			color: '#5F5F5F',
			height: '48px',
			display: 'inline-block'
		},
		editListBtn: {
			marginTop: '21px',
			marginLeft: '340px',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 500,
			fontSize: '18px',
			lineHeight: '25px',
			color: '#5F5F5F',
			height: '48px',
			width: '200px'
		},
		toolbarContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			paddingLeft: '41px',
			paddingRight: '40px',
			height: '73px'
		},
		filter: {
			position: 'relative',
			top: '-10px'
		},
		searchInput: {
			width: '300px'
		},
		toolbarRight: {
			display: 'flex',
			alignItems: 'center'
		},
		statusInformation: {
			display: 'flex',
			alignItems: 'center',
			marginRight: '32px',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 500,
			fontSize: '16px',
			lineHeight: '22px',
			color: '#5F5F5F'
		},
		onlineIndicator: {
			height: '8px',
			width: '8px',
			background: '#2AAAE1',
			borderRadius: '50%',
			display: 'inline-block',
			marginRight: '7px',
			marginBottom: '1px'
		},
		offlineIndicator: {
			height: '8px',
			width: '8px',
			background: '#FF746B',
			borderRadius: '50%',
			display: 'inline-block',
			marginLeft: '24px',
			marginRight: '7px',
			marginBottom: '1px'
		},
		divider: {
			background: '#D0D5E8',
			height: '32px',
			marginLeft: '26px'
		},
		locations: {
			marginLeft: '31px'
		},
		locationCount: {
			color: '#232F64',
			fontWeight: 'bold',
			letterSpacing: '-0.5px',
			marginLeft: '16px'
		},
		editBtnRoot: {
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			// padding: '1px 0px',
			borderRadius: '8px',
			width: '100px',
			height: '36px',
			background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)'
		},
		editBtnTxt: {
			color: '#FFFFFF',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontSize: '16px',
			lineHeight: '22px',
			fontWeight: 'bold',
			letterSpacing: '0.5px'
		},
		exportBtnDisabled: {
			opacity: '0.5'
		},
		backdrop: {
			zIndex: theme.zIndex.drawer + 1,
			color: '#fff'
		},
		label: {
			color: '#232F64',
			fontSize: '16px',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold'
		},
		labelText: {
			color: '#5F5F5F',
			fontSize: '16px',
			fontFamily: 'Manrope',
			fontStyle: 'normal'
		},
		divider2: {
			background: '#D0D5E8',
			height: '20px',
			marginLeft: '26px',
			marginRight: '26px',
			paddingBottom: '5px'
		},
		divider3: {
			height: '10px',
			marginRight: '10px',
			marginLeft: '10px',
			borderLeft: '1px solid #D0D5E8'
		},
		statusBtnRoot: {
			background: '#FFFFFF',
			boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)',
			// borderTopLeftRadius: '8px',
			// borderBottomLeftRadius: '8px',
			// borderTopRightRadius: '0',
			// borderBottomRightRadius: '0',
			width: '140px',
			height: '36px'
			// cursor: 'default'
		},
		statusBtnTxt: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '16px',
			lineHeight: '22px',
			letterSpacing: '0.5px',
			color: '#5F5F5F',
			textTransform: 'capitalize'
		},
		arrowBtnRoot: {
			background: '#FFFFFF',
			boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)',
			borderTopRightRadius: '8px',
			borderBottomRightRadius: '8px',
			borderTopLeftRadius: '0',
			borderBottomLeftRadius: '0',
			width: '20px',
			height: '36px',
			display: 'inline',
			padding: '8px'
		},
		activeIndicator: {
			height: '12px',
			width: '12px',
			color: '#3BA359'
		},
		inactiveIndicator: {
			height: '12px',
			width: '12px',
			color: '#EB5757'
		},
		arrowDropDown: {
			height: '22px',
			width: '22px'
		},
		btnContainer: {
			position: 'absolute',
			right: '200px',
			top: '140px'
		},
		importBtnContainer: {
			position: 'absolute',
			right: '40px',
			top: '140px'
		},
		menuPaper: {
			borderRadius: '8px',
			background: '#FFFFFF',
			boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.12)',
			padding: '16px 16px 0 16px',
			zIndex: 9999
		},
		menuItemRoot: {
			marginBottom: '16px',
			'&:hover': {
				background: 'linear-gradient(269.92deg, #EEEFF3 4.93%, #F2F4FB 99.9%)',
				borderRadius: '8px'
			},
			padding: '7.5px 12px',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 500,
			fontSize: '18px',
			lineHeight: '25px',
			textAlign: 'right',
			color: '#111111'
		},
		optionsContainer: {
			// width: '500px'
			marginRight: '40px'
		}
	})
);
