import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDataProvider, useMutation, useNotify, Loading, downloadCSV } from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core';
import uiColor from '../../utils/utilsColors';
import { RaBox } from 'ra-compact-ui';
import { ValueCodeHeaderGrids } from './ValueCodeHeaderGrids';
import jsonExport from 'jsonexport/dist';
import CustomTransactionList from '../transactions/CustomTransactionList';
import { TransactionsFilters } from '../transactions/TransactionsFilters';
import { ValueCode } from '../../../types';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

export const ValueCodeShow = (props: any) => {
	const classes = useStyles();
	const dataProvider = useDataProvider();
	const history = useHistory();
	const [curSort] = useState({});
	// eslint-disable-next-line no-unused-vars
	const [search, searchInput] = useState('');
	const [mutate] = useMutation();
	const notify = useNotify();
	const [record, setRecord] = useState<ValueCode | null>(null);
	const [services, setServices] = useState<any>([]);
	const [locations, setLocations] = useState<any>([]);
	const [valueCodes, setValueCodes] = useState<any>([]);
	const [filter, setFilter] = useState<any>({});
	const [hasValueStore, sethasValueStore] = useState<any>(false);

	const apiUrl = process.env.REACT_APP_API_URL as string;
	const token = localStorage.getItem('cscvc_token') as string;

	useEffect(() => {
		getCode();
		setServices(JSON.parse(localStorage.getItem('cscvc_filterServices') as string));
		setLocations(JSON.parse(localStorage.getItem('cscvc_filterLocations') as string));
	}, []);

	const codeStatusClicked = () => {
		updateValueCode();
	};

	const getCode = async () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: token
		};
		await axios({
			method: 'GET',
			url: apiUrl + `/valueCodes/${props.id}?balances=true`,
			headers: headers
		})
			.then(({ data }: any) => {
				setRecord(data);
				localStorage.setItem('valuCode', JSON.stringify(data));
				if (data.valueStoreId !== null) {
					sethasValueStore(true);
					dataProvider
						.getList('valuecodes', {
							filter: { valueStoreId: data.valueStoreId },
							sort: { field: 'name', order: 'ASC' },
							pagination: { page: 1, perPage: 10000000 }
						})
						.then(({ data }) => {
							setValueCodes(data);
						})
						.catch((error) => {
							notify('Error getting value codes from store: ' + error, 'warning');
						});
				} else sethasValueStore(false);
			})
			.catch((error) => {
				notify('Error getting code: ' + error, 'warning');
			});
	};

	const updateValueCode = () => {
		mutate(
			{
				type: 'delete',
				resource: 'valuecodes',
				payload: {
					id: record?.id
				}
			},
			{
				onSuccess: () => {
					getCode();
					notify(`Value Code:${record?.humanReadableId} Edited`);
				},
				onFailure: (error) => {
					notify(`Value Code:${record?.humanReadableId} not Edited`, 'warning');
					console.log('Error: ' + error);
				}
			}
		);
	};

	const goToConsumer = (consumerId: any) => {
		console.log(consumerId);
		history.push(`/consumers/${consumerId}/show`);
	};

	const onExports = () => {
		dataProvider
			.getList(`valuestores/${record?.valueStoreId}/transactions`, {
				filter: filter,
				sort: { field: 'transactionDate', order: 'ASC' },
				pagination: { page: 1, perPage: 1000000 }
			})
			.then((transactions: any) => {
				const transactionsForExport = transactions.data.map((transaction: any) => ({
					TransactionDate: transaction.transactionDate,
					Service: transaction.service?.name,
					Location: transaction.location?.name,
					AS400: transaction.aS400ID,
					HumanReadableId: transaction.valueCode?.humanReadableId,
					TransactionType: transaction.transactionType,
					CurrencyType: transaction.currencyType,
					Amount: `$ ${Number(transaction.amount).toFixed(2)}`
				}));
				jsonExport(
					transactionsForExport,
					{
						headers: []
					},
					// eslint-disable-next-line n/handle-callback-err
					(err, csv) => {
						downloadCSV(csv, 'transactions');
					}
				);
			});
	};
	if (record === null) {
		return <Loading loadingPrimary='Retrieving the information' loadingSecondary='Wait a moment' />;
	} else {
		return (
			<div className={classes.container}>
				<ValueCodeHeaderGrids
					{...props}
					searchInput={searchInput}
					idSearch='id'
					disableExport={true}
					curSort={curSort}
					onExports={onExports}
					id={props.id}
					codeStatusClicked={codeStatusClicked}
					record={record}
				/>
				<div className={classes.codeInformationContainer}>
					<div className={classes.subtitleValueCode}>Code Information</div>
					<span className={classes.valueCodesItemLabel}>Human Readable ID</span>
					<span className={classes.valueCodesItemText}>{record?.humanReadableId}</span>
				</div>
				<div className={classes.codeInformationContainer}>
					<div className={classes.subtitleValueCode}></div>
					<span className={classes.valueCodesItemLabel}>Consumer</span>
					<span
						onClick={() => goToConsumer(record?.consumer.id)}
						className={classes.valueCodesItemTextBlue}
					>
						{record?.consumer?.firstName} {record?.consumer?.lastName}
					</span>
				</div>
				<div className={classes.codeInformationContainer}>
					<div className={classes.subtitleValueCode}></div>
					<span className={classes.valueCodesItemLabel}>Value Store ID</span>
					<span className={classes.valueCodesItemText}>{record?.valueStoreId}</span>
				</div>
				<div className={classes.codeInformationContainer}>
					<div className={classes.subtitleValueCode}></div>
					<span className={classes.valueCodesItemLabel}>NFC UID</span>
					<span className={classes.valueCodesItemText}>{record?.nfcuid}</span>
				</div>
				<div className={classes.codeInformationContainer}>
					<div className={classes.subtitleValueCode}></div>
					<span className={classes.valueCodesItemLabel}>Balances</span>
					<div>
						{record.balances.map((balance: any, index: number) => {
							return (
								<span key={index} className={classes.valueCodesItemText}>
									{Number(balance.balance)}&nbsp;&nbsp;{balance.currencyType}
								</span>
							);
						})}
					</div>
				</div>
				<div className={classes.codeInformationContainer}>
					<div className={classes.subtitleValueCode}></div>
					<span className={classes.valueCodesItemLabel}>Status</span>
					{record?.active && <span className={classes.active}>Active</span>}
					{!record?.active && <span className={classes.inactive}>Inactive</span>}
				</div>
				<div className={classes.codeInformationContainer}>
					<div className={classes.subtitleValueCode}></div>
					<span className={classes.valueCodesItemLabel}>External Unique Id</span>
					<span className={classes.valueCodesItemText}>{record?.externalUniqueId}</span>
				</div>
				{record?.serviceMetadataJSON && (
					<div>
						<div className={classes.codeInformationContainer}>
							<div className={classes.subtitleValueCode}></div>
							<span className={classes.valueCodesItemLabel}>AS400ID (meta-data)</span>
							<span className={classes.valueCodesItemText}>
								{record?.serviceMetadataJSON?.aS400ID}
							</span>
						</div>
						<div className={classes.codeInformationContainer}>
							<div className={classes.subtitleValueCode}></div>
							<span className={classes.valueCodesItemLabel}>EPORTID (meta-data)</span>
							<span className={classes.valueCodesItemText}>
								{record?.serviceMetadataJSON?.eportID}
							</span>
						</div>
						<div className={classes.codeInformationContainer}>
							<div className={classes.subtitleValueCode}></div>
							<span className={classes.valueCodesItemLabel}>License Tag (meta-data)</span>
							<span className={classes.valueCodesItemText}>
								{record?.serviceMetadataJSON?.licenseTag}
							</span>
						</div>
						<div className={classes.codeInformationContainer}>
							<div className={classes.subtitleValueCode}></div>
							<span className={classes.valueCodesItemLabel}>Price$ (meta-data)</span>
							<span className={classes.valueCodesItemText}>
								{record?.serviceMetadataJSON?.price$}
							</span>
						</div>
						<div className={classes.codeInformationContainer}>
							<div className={classes.subtitleValueCode}></div>
							<span className={classes.valueCodesItemLabel}>TxId (meta-data)</span>
							<span className={classes.valueCodesItemText}>
								{record?.serviceMetadataJSON?.txId}
							</span>
						</div>
						<div className={classes.codeInformationContainer}>
							<div className={classes.subtitleValueCode}></div>
							<span className={classes.valueCodesItemLabel}>VendTimeSecs (meta-data)</span>
							<span className={classes.valueCodesItemText}>
								{record?.serviceMetadataJSON?.vendTimeSecs}
							</span>
						</div>
						<div className={classes.codeInformationContainer}>
							<div className={classes.subtitleValueCode}></div>
							<span className={classes.valueCodesItemLabel}>Redeemed (meta-data)</span>
							{record?.serviceMetadataJSON?.redeemed && (
								<span className={classes.active}>True</span>
							)}
							{!record?.serviceMetadataJSON?.redeemed && (
								<span className={classes.inactive}>False</span>
							)}
						</div>
					</div>
				)}
				<div className={classes.codeInformationContainer}>
					<div className={classes.subtitleValueCode}></div>
					<span className={classes.valueCodesItemLabel}>Activation Date</span>
					<span className={classes.valueCodesItemText}>
						{moment(record?.createdOn).format('MM/DD/YYYY h:mm A')}
					</span>
				</div>
				<div className={classes.codeInformationContainer}>
					<div className={classes.subtitleValueCode}></div>
					<span className={classes.valueCodesItemLabel}>Last Updated</span>
					<span className={classes.valueCodesItemText}>
						{moment(record?.lastUpdated).format('MM/DD/YYYY h:mm A')}
					</span>
				</div>
				<div className={classes.codeInformationContainer}>
					<div className={classes.subtitleValueCode}></div>
					<span className={classes.valueCodesItemLabel}>First Use</span>
					{record?.firstUse !== null && (
						<span className={classes.valueCodesItemText}>
							{moment(record?.firstUse).format('MM/DD/YYYY h:mm A')}
						</span>
					)}
				</div>
				<div className={classes.codeInformationContainer}>
					<div className={classes.subtitleValueCode}></div>
					<span className={classes.valueCodesItemLabel}>Expiration Date</span>
					<span className={classes.valueCodesItemText}>
						{moment(record?.expirationDate).format('MM/DD/YYYY')}
					</span>
				</div>
				<div className={classes.subtitle}>Activity</div>
				<RaBox marginRight='30px' marginLeft='30px' marginTop='10px'>
					<TransactionsFilters
						filter={filter}
						setFilter={setFilter}
						onExports={onExports}
						locations={locations}
						services={services}
						showExportBtn={true}
						valueCodes={valueCodes}
						valueStoreFilter={hasValueStore}
					/>
					<CustomTransactionList valueStoreId={record?.valueStoreId} filter={filter} {...props} />
				</RaBox>
			</div>
		);
	}
};

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			marginTop: '1em'
		},
		showLayout: {
			padding: 0,
			'&:first-child': {
				paddingTop: '0px'
			},
			'&:last-child': {
				paddingBottom: '0px'
			}
		},
		gridWrapper: {
			display: 'flex',
			margin: '0px 40px'
		},
		table: {
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			borderRadius: '8px',
			background: '#FFFFFF',
			minWidth: '1191px'
		},
		thead: {
			borderTopLeftRadius: '8px',
			borderTopRightRadius: '8px'
		},
		tbody: {
			borderBottomLeftRadius: '8px',
			borderBottomRightRadius: '8px',
			border: '1px solid #EEEFF3'
		},
		headerCell: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '16px',
			lineHeight: '22px',
			letterSpacing: '-0.5px',
			color: '#111111',
			paddingTop: '12px',
			paddingBottom: '15px',
			paddingLeft: '24px',
			'&:first-child': {
				width: '20px',
				paddingLeft: '0',
				paddingRight: '0'
			},
			'&:nth-child(2)': {
				width: '350px'
			},
			'&:last-child': {
				width: '50px'
			}
		},
		locationName: {
			fontWeight: 'bold',
			color: uiColor.secondary,
			letterSpacing: '-0.5px'
		},
		tableRow: {
			'&$hover:hover': {
				background: 'linear-gradient(269.92deg, #EEEFF3 4.93%, #F2F4FB 99.9%)',
				cursor: 'pointer'
			}
		},
		hover: {},
		tableCell: {
			paddingTop: '16px',
			paddingBottom: '16px',
			borderRight: '1px solid #EEEFF3',
			paddingLeft: '24px'
		},
		tableCellNoBorder: {
			paddingTop: '16px',
			paddingBottom: '16px',
			paddingLeft: '24px'
		},
		showBtn: {
			visibility: 'hidden',
			'$hover:hover &': {
				visibility: 'visible'
			}
		},
		normalField: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 500,
			fontSize: '16px',
			lineHeight: '22px',
			color: '#111111'
		},
		addRoomBtnRoot: {
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			padding: '1px 0px',
			borderRadius: '8px',
			width: '154px',
			height: '36px',
			background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)'
		},
		addRoomBtnTxt: {
			color: '#FFFFFF',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontSize: '16px',
			lineHeight: '22px',
			fontWeight: 'bold',
			letterSpacing: '0.5px'
		},
		tabsRoot: {
			borderBottom: '1px solid lightgray',
			padding: '0 40px'
		},
		tabsIndicator: {
			background: '#1695CB'
		},
		tabRoot: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			letterSpacing: '0.75px',
			fontWeight: 'bold',
			fontSize: '18px',
			lineHeight: '25px',
			color: '#969696',
			padding: 0,
			minWidth: '73px',
			marginRight: '32px'
		},
		tabSelected: {
			color: '#1695CB'
		},
		humanReadableId: {
			fontWeight: 'bold',
			color: '#1695CB',
			cursor: 'pointer'
		},
		codeInformationContainer: {
			display: 'flex',
			justifyContent: 'flex-start',
			margin: '33px 40px 0 40px',
			flexDirection: 'row'
		},
		subtitleValueCode: {
			alignItems: 'center',
			color: '#111111',
			fontWeight: 700,
			fontSize: '18px',
			fontFamily: 'Manrope',
			lineHeight: '25px',
			width: '250px'
		},
		valueCodesItemLabel: {
			fontWeight: 500,
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontSize: '18px',
			lineHeight: '25px',
			letterSpacing: '-0.5px',
			color: '#111111',
			width: '250px'
		},
		valueCodesItemText: {
			fontWeight: 700,
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontSize: '16px',
			lineHeight: '25px',
			letterSpacing: '-0.5px',
			color: '#111111',
			width: '400px',
			display: 'block'
		},
		valueCodesItemTextBlue: {
			fontWeight: 700,
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontSize: '16px',
			lineHeight: '25px',
			letterSpacing: '-0.5px',
			color: '#1695CB',
			width: '400px',
			display: 'block',
			cursor: 'pointer'
		},
		statusButton: {
			marginLeft: 'auto'
		},
		status: {
			color: '#2D7D44',
			backgroundColor: '#E9F7ED',
			fontWeight: 500,
			padding: '3px 10px'
		},
		active: {
			color: '#2D7D44',
			backgroundColor: '#E9F7ED',
			fontWeight: 500,
			padding: '3px 10px'
		},
		inactive: {
			color: '#5F5F5F',
			backgroundColor: '#EDEDED',
			fontWeight: 500,
			padding: '3px 10px'
		},
		statusBtnRoot: {
			background: '#FFFFFF',
			boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)',
			borderTopLeftRadius: '8px',
			borderBottomLeftRadius: '8px',
			borderTopRightRadius: '0',
			borderBottomRightRadius: '0',
			width: '110px',
			height: '30px',
			cursor: 'default'
		},
		statusBtnTxt: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '16px',
			lineHeight: '22px',
			letterSpacing: '0.5px',
			color: '#5F5F5F',
			textTransform: 'capitalize'
		},
		arrowBtnRoot: {
			background: '#FFFFFF',
			boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)',
			borderTopRightRadius: '8px',
			borderBottomRightRadius: '8px',
			borderTopLeftRadius: '0',
			borderBottomLeftRadius: '0',
			width: '20px',
			height: '36px',
			display: 'inline',
			padding: '7px'
		},
		activeIndicator: {
			height: '8px',
			width: '8px',
			background: '#3BA359',
			borderRadius: '50%',
			display: 'inline-block',
			marginRight: '7px',
			marginBottom: '1px'
		},
		inactiveIndicator: {
			height: '8px',
			width: '8px',
			background: '#EB5757',
			borderRadius: '50%',
			display: 'inline-block',
			marginRight: '7px',
			marginBottom: '1px'
		},
		subtitle: {
			margin: '33px 40px 5px 40px',
			paddingBottom: '10px',
			display: 'flex',
			alignItems: 'center',
			color: '#111111',
			fontWeight: 700,
			fontSize: '18px',
			fontFamily: 'Manrope',
			lineHeight: '25px',
			borderBottom: '1px solid #E2E3E9'
		}
	})
);
