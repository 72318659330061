import React, { useState, useEffect } from 'react';
import {
	Table,
	TableCell,
	TableRow,
	TableContainer,
	TableHead,
	TableBody,
	makeStyles,
	createStyles,
	TextField,
	FormControl,
	Select,
	MenuItem
} from '@material-ui/core';
import { useNotify, useDataProvider } from 'react-admin';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Create';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import { DeleteCurrencyTypeDialog } from './Dialogs/DeleteCurrencyTypeDialog';
import NumberFormat from 'react-number-format';

interface NumberFormatCustomProps {
	inputRef: (instance: NumberFormat<any> | null) => void;
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
}

function AmountPerCodeIntegerFormatter(props: NumberFormatCustomProps) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value
					}
				});
			}}
			thousandSeparator
			allowNegative={false}
			decimalScale={0}
		/>
	);
}

function AmountPerCodeDecimalFormatter(props: NumberFormatCustomProps) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value
					}
				});
			}}
			thousandSeparator
			allowNegative={false}
			decimalScale={2}
			fixedDecimalScale
		/>
	);
}

export const CurrencyTypesTable = ({
	list,
	initialAmounts,
	setInitialAmounts,
	initialAmountEdit,
	setInitialAmountEdit
}: any) => {
	const classes = useStyles();
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const [currencyTypes, setCurrencyTypes] = useState<any[]>([]);
	const [openDeleteCurrencyType, setOpenDeleteCurrencyType] = useState(false);
	const [selectedInitialAmountIndex, setSelectedInitialAmountIndex] = useState(0);
	const [pendingInitialAmount, setPendingInitialAmount] = useState(false);

	useEffect(() => {
		dataProvider
			.getList(`services/${list.serviceId}/currencytypes`, {
				filter: {},
				sort: { field: 'name', order: 'ASC' },
				pagination: { page: 0, perPage: 1000000 }
			})
			.then(({ data }) => {
				setCurrencyTypes(data);
			})
			.catch((error) => {
				notify('Error getting services: ' + error, 'warning');
			});
	}, [list]);

	useEffect(() => {
		let pendingEdit = false;
		initialAmountEdit.forEach((element: any) => {
			if (element.edit === true) pendingEdit = true;
		});
		setPendingInitialAmount(pendingEdit);
	}, [initialAmountEdit]);

	useEffect(() => {
		if (currencyTypes.length > 0 && initialAmounts.length > 0 && initialAmountEdit.length > 0) {
			const initialAmountsEditCopy = initialAmountEdit.slice();
			initialAmounts.forEach((initialAmount: any, index: any) => {
				currencyTypes.forEach((currencyType: any) => {
					if (initialAmount.currencyId === currencyType.id)
						initialAmountsEditCopy[index].monetary = currencyType.monetary;
				});
			});
		}
	}, [currencyTypes, initialAmounts]);

	const deleteCurrencyTypeDialogClicked = () => {
		setOpenDeleteCurrencyType(false);
		const initialAmountsCopy = initialAmounts.slice();
		initialAmountsCopy.splice(selectedInitialAmountIndex, 1);
		setInitialAmounts(initialAmountsCopy);
		const initialAmountsEditCopy = initialAmountEdit.slice();
		initialAmountsEditCopy.splice(selectedInitialAmountIndex, 1);
		setInitialAmountEdit(initialAmountsEditCopy);
	};

	const deleteCurrencyTypeDialogClosed = () => {
		setOpenDeleteCurrencyType(false);
	};

	const editInitialAmount = (index: any) => {
		const initialAmountEditCopy = initialAmountEdit.slice();
		initialAmountEditCopy[index].edit = true;
		setInitialAmountEdit(initialAmountEditCopy);
	};

	const amountChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
		const initialAmountsCopy = initialAmounts.slice();
		initialAmountsCopy[parseInt(event.target.name)].amount = event.target.value;
		setInitialAmounts(initialAmountsCopy);
	};

	const currencyChanged = (event: any) => {
		const initialAmountsCopy = initialAmounts.slice();
		const initialAmountsEditCopy = initialAmountEdit.slice();
		initialAmountsCopy[parseInt(event.target.name)].currencyId = event.target.value;
		currencyTypes.forEach((currecyType: any) => {
			if (currecyType.id === event.target.value) {
				initialAmountsCopy[parseInt(event.target.name)].currency = currecyType.name;
				initialAmountsCopy[parseInt(event.target.name)].amount = '';
			}
			currencyTypes.forEach((currencyType: any) => {
				if (currencyType.id === event.target.value)
					initialAmountsEditCopy[parseInt(event.target.name)].monetary = currencyType.monetary;
			});
		});
		setInitialAmounts(initialAmountsCopy);
		setInitialAmountEdit(initialAmountsEditCopy);
	};

	const renderCurrencyTypesForSelect = () => {
		const currencyTypesArray: any[] = [];

		currencyTypes.forEach((currencyType: any) => {
			currencyTypesArray.push(
				<MenuItem value={currencyType.id}>
					{currencyType.name}
					{!currencyType.description ? '' : ` - ${currencyType.description}`}
				</MenuItem>
			);
		});

		return currencyTypesArray;
	};

	const saveInitialAmount = (index: any) => {
		if (initialAmounts[index].amount !== '') {
			const initialAmountEditCopy = initialAmountEdit.slice();
			initialAmountEditCopy[index].edit = false;
			setInitialAmountEdit(initialAmountEditCopy);
		}
	};

	const deleteInitialAmount = (index: any) => {
		setOpenDeleteCurrencyType(true);
		setSelectedInitialAmountIndex(index);
	};

	return (
		<>
			{initialAmounts.length > 0 ? (
				<TableContainer component={Paper}>
					<Table className={classes.table} aria-label='simple table'>
						<TableHead>
							<TableRow>
								<TableCell className={classes.tableHead}>Currency Type</TableCell>
								<TableCell className={classes.tableHead}>Amount Per Code</TableCell>
								<TableCell className={classes.tableHead}></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{initialAmounts.length > 0 &&
								initialAmounts.map((initialAmount: any, index: any) => (
									<TableRow key={index}>
										<TableCell>
											{!initialAmountEdit[index].edit ? (
												initialAmount.currency
											) : (
												<FormControl variant='outlined' size='small'>
													<Select
														error={initialAmounts[index].currencyId === ''}
														onChange={currencyChanged}
														labelWidth={0}
														value={initialAmounts[index].currencyId}
														inputProps={{
															name: index,
															id: 'outlined-select-currency'
														}}
														classes={{
															root: classes.inputSelectRoot,
															outlined: classes.inputSelectOutlined,
															select: classes.inputSelectSelected
														}}
													>
														<MenuItem value=''>
															<em>Select the currency...</em>
														</MenuItem>
														{currencyTypes.length > 0 && renderCurrencyTypesForSelect()}
													</Select>
												</FormControl>
											)}
										</TableCell>
										<TableCell>
											{!initialAmountEdit[index].edit ? (
												initialAmount.amount
											) : (
												<TextField
													id='outlined-helperText'
													variant='outlined'
													size='small'
													error={initialAmounts[index].amount === ''}
													onChange={amountChanged}
													className={classes.editableField}
													value={initialAmounts[index].amount}
													name={index}
													InputProps={{
														inputComponent: initialAmountEdit[index].monetary
															? (AmountPerCodeDecimalFormatter as any)
															: (AmountPerCodeIntegerFormatter as any)
													}}
												/>
											)}
										</TableCell>
										<TableCell>
											{!initialAmountEdit[index].edit ? (
												<EditIcon
													className={classes.editIcon}
													onClick={() => editInitialAmount(index)}
												/>
											) : (
												<div className={classes.iconsContainer}>
													<CheckIcon
														className={classes.checkIcon}
														onClick={() => saveInitialAmount(index)}
													/>
													<span className={classes.iconDivider}>&nbsp;</span>
													<DeleteIcon
														className={classes.deleteIcon}
														onClick={() => deleteInitialAmount(index)}
													/>
												</div>
											)}
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			) : (
				<span className={classes.noResults}>0 Results</span>
			)}
			{}
			{pendingInitialAmount && (
				<span className={classes.errorText}>Please Enter Currency Type and Amount.</span>
			)}
			<DeleteCurrencyTypeDialog
				openDeleteCurrencyType={openDeleteCurrencyType}
				deleteCurrencyTypeClosed={deleteCurrencyTypeDialogClosed}
				deleteCurrencyTypeClicked={deleteCurrencyTypeDialogClicked}
			/>
		</>
	);
};

const useStyles = makeStyles(() =>
	createStyles({
		table: {
			minWidth: 400
		},
		tableHead: {
			fontFamily: 'Manrope',
			fontWeight: 700
		},
		editIcon: {
			width: '20px',
			height: '20px',
			color: '#2AAAE1',
			cursor: 'pointer'
		},
		checkIcon: {
			width: '25px',
			height: '25px',
			color: '#3BA359',
			cursor: 'pointer',
			marginRight: '20px'
		},
		deleteIcon: {
			width: '25px',
			height: '25px',
			color: '#EB5757',
			cursor: 'pointer'
		},
		iconsContainer: {
			position: 'relative'
		},
		iconDivider: {
			borderLeft: '1px solid #BDBDBD',
			position: 'absolute',
			margin: '0 7px',
			right: '30px',
			top: '2px'
		},
		editableField: {
			maxWidth: '120px'
		},
		currencySelect: {
			width: '120px',
			height: '40px'
		},
		inputSelectRoot: {
			padding: '8px 16px 0 16px',
			width: '60px',
			height: '30px'
		},
		inputSelectOutlined: {
			borderRadius: '8px',
			'&.MuiSelect-outlined': {
				paddingRight: '48px'
			}
		},
		inputSelectSelected: {
			'&:focus': {
				background: '#F2F4FB'
			}
		},
		errorText: {
			fontWeight: 700,
			fontSize: '12px',
			fontFamily: 'Manrope',
			color: '#EB5757',
			margin: '10px'
		},
		noResults: {
			fontWeight: 500,
			fontSize: '16px',
			fontFamily: 'Manrope',
			marginLeft: '5px'
		}
	})
);
